import * as React from "react";
import { useLayoutEffect, useState, useEffect } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import CTAButton from "../../components/CTAButton";
import TextStrokeHeader from "../../components/TextStrokeHeader";
import LinkWrapper from "../../components/LinkWrapper";
import { IoIosArrowForward } from "react-icons/io";
import useWindowSize from "../../utitlities/useWindowSize";

/* IMPORT APP CONTEXT */
import { AppContext } from "../../context";

const isBrowser = typeof window !== "undefined";

function ShopByCategory(props) {
  const windowSize = useWindowSize();
  const appContext = React.useContext(AppContext);
  const [device, setDevice] = useState("xl");

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      sanityPage(slug: { current: { eq: "/" } }) {
        sections {
          ... on SanityShopByCategory {
            _key
            _type
            header
            title
            subtitle
            newBlendImg {
              image {
                asset {
                  gatsbyImageData(width: 703, height: 674, placeholder: NONE)
                }
              }
              externalLink
              internallLink {
                route
              }
            }
            newBlendSubTitle
            newBlendTitle
            shopAllImg {
              asset {
                gatsbyImageData(width: 703, height: 674, placeholder: NONE)
              }
            }
            shopAllRoute {
              route
            }
            productCategories {
              subcategory {
                slug {
                  current
                }
                name
                ctaRoute {
                  route
                }
                coverImage {
                  asset {
                    gatsbyImageData(width: 703, height: 674, placeholder: NONE)
                  }
                }
                category {
                  name
                  slug {
                    current
                  }
                }
              }
              category {
                slug {
                  current
                }
                name
                ctaRoute {
                  route
                }
                coverImage {
                  asset {
                    gatsbyImageData(width: 703, height: 674, placeholder: NONE)
                  }
                }
              }
              image {
                asset {
                  gatsbyImageData(width: 703, height: 674, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  `);

  const sectionData = data.sanityPage.sections.find(
    (item) => item?._type === "shopByCategory"
  );

  useEffect(() => {
    if (!isBrowser) return null;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDevice("Mobile");
    } else {
      setDevice("Desktop");
    }
  }, []);

  const [imageIndex, setImageIndex] = React.useState(-2);

  return (
    <Flex
      w={{ base: "87%", md: "87%", mds: "84%", lg: "84%", xl: "1200px" }}
      mx="auto"
      mt={{ base: "18vw", ssm: "15vw", ms: "15vw", md: "9vw", xl: "121px" }}
      flexDirection="column"
      // border="1px"
    >
      {/* HEADER */}
      <TextStrokeHeader
        text={sectionData.header}
        fontSize={{
          base: "13.5vw",
          ssm: "13.5vw",
          ms: "13.5vw",
          msx: "13.5vw",
          md: "13.2vw",
          mds: "13.2vw",
          lg: "13.2vw",
          xl: "190px",
        }}
        letterSpacing={{ base: "0px", ssm: "-0.5px", msx: "-0.5px" }}
        mb={{
          base: "-2.2vw",
          ssm: "-2.2vw",
          ms: "-2.5vw",
          msx: "-2.5vw",
          md: "-3.26vw",
          mds: "-3.1vw",
          lg: "-3.22vw",
          lgs: "-2.8vw",
          xl: "-40px",
        }}
        opacity={"0.2"}
        noOfLines={{ base: 1 }}
        lineHeight={{ base: "0.8", md: "0.9" }}
      />

      {/* CONTENT */}
      <Flex
        w="100%"
        h={{ base: "auto", msx: "674px" }}
        zIndex="2"
        direction={{ base: "column-reverse", msx: "row" }}
        // border="1px"
      >
        {/* LEFT CONTENT */}
        <Flex
          w={{ base: "100%", msx: "41%", xl: "497px" }}
          direction={{ base: "column" }}
          justifyContent={{ base: "center", msx: "left" }}
          bgColor={"primaryDarker"}
          p={{
            base: "4vw",
            msx: "4vw",
            md: "4vw",
            mds: "3vw 4vw 3vw 4vw",
            lg: "3vw 5vw 3vw 5vw",
            lgs: "2vw 0vw 3vw 5vw",
            xl: "26px 0px 43px 70px",
          }}
        >
          <Heading
            as="h2"
            color="#F4F4F4"
            fontFamily="Outfit"
            fontSize={{ base: "30px", msx: "34px" }}
            fontWeight={{ base: "500", md: "500" }}
            lineHeight={{
              base: "30px",
              msx: "34px",
              md: "34px",
              lgs: "65px",
              xl: "65px",
            }}
            letterSpacing={{ base: "1.07px", md: "1.07px" }}
            textAlign={{ base: "center", msx: "left" }}
            mb={{ base: "10px", msx: "10px", lgs: "0px" }}
          >
            {sectionData.title}
          </Heading>

          <Text
            color="white"
            fontFamily="Lato"
            fontStyle="italic"
            fontSize={{ base: "15px", md: "15px" }}
            fontWeight={{ base: "500", md: "500" }}
            lineHeight={{ base: "20px", md: "20px" }}
            letterSpacing={{ base: "0px", md: "0px" }}
            textAlign={{ base: "center", msx: "left" }}
            px={{ base: "1px", md: "1px" }}
            mb={{ base: "5px", md: "5px" }}
          >
            {sectionData.subtitle}
          </Text>

          {/* ALL CATEGORIES */}
          <Box maxW={{ base: "100%", msx: "322px" }}>
            {sectionData.productCategories.map((productType, indx) => {
              const addMouseEvents =
                device === "Mobile"
                  ? {}
                  : {
                      onMouseEnter: () => setImageIndex(indx),
                      onMouseLeave: () => setImageIndex(-2),
                    };
              return (
                <LinkWrapper
                  href={
                    productType.subcategory
                      ? productType.subcategory.ctaRoute.route
                      : productType.category.ctaRoute.route
                  }
                  key={indx}
                >
                  <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={{ base: "14px", md: "14px" }}
                    color="white"
                    borderBottom="1px"
                    borderColor="white"
                    _hover={{ borderColor: "#01A7CF", color: "#01A7CF" }}
                    {...addMouseEvents}
                  >
                    {/* CATEGORY CONTENT */}
                    <Box>
                      <Text
                        fontSize={{
                          base: "20px",
                          msx: "17px",
                          md: "19px",
                          mds: "21px",
                          lg: "23px",
                          lgs: "25px",
                          xl: "27px",
                        }}
                        fontWeight={{ base: "900", md: "900" }}
                        lineHeight={{
                          base: "23px",
                          msx: "26px",
                          md: "28px",
                          mds: "30px",
                          lg: "32px",
                          lgs: "34px",
                          xl: "36px",
                        }}
                        padding={{ base: "6px", md: "6px" }}
                        letterSpacing={{ base: "0.96px", md: "0.96px" }}
                        textTransform="uppercase"
                      >
                        {productType.subcategory
                          ? productType.subcategory.name
                          : productType.category.name}
                      </Text>
                    </Box>

                    {/* ARROW ICON */}
                    <IoIosArrowForward style={{ width: "25px", height: "25px" }} />
                  </Flex>
                </LinkWrapper>
              );
            })}

            {/* BUTTON */}
            <Box
              maxW={{ base: "322px" }}
              mx={{ base: "auto", msx: "0" }}
              mt={{ base: "43px", msx: "43px", md: "43px" }}
              mb={{ base: "23px", md: "0px" }}
              onMouseEnter={() => setImageIndex(-1)}
              onMouseLeave={() => setImageIndex(-2)}
            >
              <CTAButton
                href={sectionData.shopAllRoute.route}
                fontSize={{ base: "13px" }}
                bgColor={"transparent"}
                color={"white"}
                borderColor={"white"}
                border={"1px"}
                width={{ base: "100%" }}
                height={{ base: "48px" }}
                _hover={{
                  bg: "#FFFFFF",
                  color: "#01A7CF",
                  borderColor: "#01A7CF",
                }}
                ctaText={"shop all"}
              />
            </Box>
          </Box>
        </Flex>

        {/* RIGHT CONTENT */}
        <Box
          w={{ base: "100%", msx: "59%", md: "59%", xl: "703px" }}
          h={{ base: "85vw", msx: "674px" }}
          overflow="hidden"
          bgColor="white"
          position="relative"
        >
          {/* SHOP ALL IMAGE */}
          <CategoryImage
            image={sectionData.shopAllImg.asset.gatsbyImageData}
            alt={"wana shop all image"}
            shouldDisplay={imageIndex === -1}
            linkTo={sectionData.shopAllRoute.route}
          />

          {/* ALL PRODUCT CATEGORIES AND SUBCATEGORIES */}
          {sectionData.productCategories.map((productType, indx) => {
            return (
              <CategoryImage
                key={indx}
                image={
                  sectionData.image
                    ? sectionData.image.asset.gatsbyImageData
                    : productType.subcategory
                    ? productType.subcategory.coverImage.asset.gatsbyImageData
                    : productType.category.coverImage.asset.gatsbyImageData
                }
                alt={"wana product type image"}
                shouldDisplay={imageIndex === indx}
                linkTo={
                  productType.subcategory
                    ? productType.subcategory.ctaRoute.route
                    : productType.category.ctaRoute.route
                }
              />
            );
          })}

          {/* NEW BLEND OR PROMO IMAGE */}
          <CategoryImage
            image={sectionData.newBlendImg?.image.asset.gatsbyImageData}
            alt={"wana promo or new product"}
            shouldDisplay={imageIndex === -2}
            linkTo={
              sectionData.newBlendImg?.internallLink.route ||
              sectionData.newBlendImg?.externalLink
            }
          />

          {/* NEW BLEND TEXT */}
          {imageIndex === -2 && (
            <Box
              position="absolute"
              top={{ base: "unset", msx: "31rem", lg: "31rem" }}
              bottom={{ base: "0rem", msx: "unset" }}
              w="full"
              textAlign="center"
              color="white"
            >
              {sectionData?.newBlendTitle && (
                <Text
                  maxH={{ lg: "7rem" }}
                  fontSize={{
                    base: sectionData?.newBlendTitle?.length > 14 ? "7vw" : "10vw",
                    msx:
                      sectionData?.newBlendTitle?.length > 14 ? "38px" : "46.08px",
                    md: sectionData?.newBlendTitle?.length > 14 ? "45px" : "4vw",
                  }}
                  fontWeight={900}
                >
                  {sectionData.newBlendTitle}
                </Text>
              )}
              {sectionData?.newBlendSubTitle && (
                <Text
                  maxH={{
                    base: "2rem",
                    ssm: "2.4rem",
                    ms: "3.5rem",
                    msx: "3.5rem",
                    md: "3.5rem",
                    lg: "4.5rem",
                  }}
                  fontSize={{
                    base: "6vw",
                    msx: "28px",
                    md: sectionData?.newBlendTitle?.length > 14 ? "31.5px" : "4.1vw",
                    lg: "28px",
                  }}
                  fontWeight={900}
                  overflow="hidden"
                >
                  {sectionData.newBlendSubTitle}
                </Text>
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

const CategoryImage = ({ image, alt, shouldDisplay, linkTo }) => {
  return (
    <Box
      bgColor={{ msx: "primaryDarker" }}
      w={{ base: "100%" }}
      h={{ base: "85vw", msx: "674px" }}
      position="absolute"
      top="0"
      left="0"
      opacity={shouldDisplay ? 1 : 0}
      className={`shop-by-category-img ${shouldDisplay ? "fadeInCategory" : ""}`}
    >
      <LinkWrapper href={linkTo}>
        <GatsbyImage
          image={image}
          alt={alt}
          // objectFit="contain"
        />
      </LinkWrapper>
    </Box>
  );
};

export default ShopByCategory;
